window.lookupCompetencyLevels = function(listenSelectComponent, updateSelectComponent) {
  var url = "/api/competency_level/by_competency/"
  var updateComponent = $(updateSelectComponent);

  $(listenSelectComponent).on('change', function(event) {
    fetchData();
  });

  function fetchData() {
    var selectedId = $(listenSelectComponent + " option:selected").val();
    $.get(url + selectedId, function(data) {
      updateSelectComponentData(data);
    });
  }

  function updateSelectComponentData(data) {
    updateComponent.empty();
    $.each(data.levels, function(key,value) {
      updateComponent.append($("<option></option>")
        .attr("value", value.id).text(value.display));
    });
  }
}