$(document).on('turbolinks:load', function () {

  var mppSummaryOrgsSelect = $('#monitoring-dashboards-mpp-summary-orgs-select');
  if (mppSummaryOrgsSelect.length) {
    mppSummaryOrgsSelect.multiselect({
      includeSelectAllOption: true,
      disableIfEmpty: true,
      maxHeight: 200,
      buttonWidth: '300px'
    });
  }

  var mppDetailLocationsSelect = $('#monitoring-dashboards-mpp-detail-locations-select');
  if (mppDetailLocationsSelect.length) {
    mppDetailLocationsSelect.multiselect({
      includeSelectAllOption: true,
      disableIfEmpty: true,
      maxHeight: 200,
      buttonWidth: '300px'
    });
  }

  var mppSummarySubmitBtn = $("#monitoring-dashboards-mpp-summary-search-submit-btn");
  if (mppSummarySubmitBtn.length) {
    var orgsSelect = $("#monitoring-dashboards-mpp-summary-orgs-select");
    orgsSelect.on('change', function(event) {
      mppSummarySubmitBtn.click(); 
    });
  }

  var mppDetailSubmitBtn = $("#monitoring-dashboards-mpp-detail-search-submit-btn");
  if (mppDetailSubmitBtn.length) {
    var locsSelect = $("#monitoring-dashboards-mpp-detail-locations-select");
    locsSelect.on('change', function(event) {
      mppDetailSubmitBtn.click(); 
    });
  }

  // monitoring dashboard - attendance
  var attendanceDate = $("#monitoring_dashboard_attendance_date_field");
  var attendancePastDate = $("#monitoring_dashboard_attendance_past_day");
  var attendanceNextDate = $("#monitoring_dashboard_attendance_next_day");
  var attendanceFindForm = $("#monitoring_dashboard_attendance_find_form");
  if (attendanceDate.length && attendancePastDate.length && attendanceNextDate.length && attendanceFindForm.length) {
    attendancePastDate.on("click", function() {
      var sdt = attendanceDate.val();
      if (sdt != '') {
        var dt = new Date(sdt);
        dt.setDate(dt.getDate()-1);
        attendanceDate.val(dt.toISOString().split('T')[0]);
      }
    });

    attendanceNextDate.on("click", function() {
      var sdt = attendanceDate.val();
      if (sdt != '') {
        var dt = new Date(sdt);
        dt.setDate(dt.getDate()+1);
        attendanceDate.val(dt.toISOString().split('T')[0]);
      }
    }); 
  }
});
