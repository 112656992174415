$(document).on('turbolinks:load', function () {

  var attendanceCorrectionDataSearch = $('a#attendance_correction_data_search');
  var attendanceCorrectionSchedule = $('#attendance_correction_schedule');
  if (attendanceCorrectionDataSearch.length && attendanceCorrectionSchedule.length) {
    $(attendanceCorrectionDataSearch).on('click', function(e) {
      e.preventDefault();
      fetchData();
    });

    function fetchData() {
      $.get('/api/attendance_corrections/get_attendance_data?schedule=' +  attendanceCorrectionSchedule.val(), function(data) {
        updateData(data);
      });
    }

    function updateData(data) {
      if (data.attendance) {
        var attCorrClockin = $('#attendance_correction_clockin');
        if (attCorrClockin.length) {
          attCorrClockin.val(data.attendance.clockin);
        }

        var attCorrClockout = $('#attendance_correction_clockout');
        if (attCorrClockout.length) {
          attCorrClockout.val(data.attendance.clockout);
        }

        var attCorrBreakin = $('#attendance_correction_breakin');
        if (attCorrBreakin.length) {
          attCorrBreakin.val(data.attendance.breakin);
        }

        var attCorrBreakout = $('#attendance_correction_breakout');
        if (attCorrBreakout.length) {
          attCorrBreakout.val(data.attendance.breakout);
        }
      }
    }
  }
});