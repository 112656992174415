$(document).on('turbolinks:load', function () {
  
  var candidateCareerStatusSelect = $('select#candidate_career_status_id');
  var candidatePeriodNumber = $('input#candidate_period');
  if (candidateCareerStatusSelect.length && candidatePeriodNumber.length) {
    
    $(candidateCareerStatusSelect).on('change', function() {
      fetchData();
    });

    function fetchData() {
      var cid = $("select#candidate_career_status_id option:selected").val();
      $.get('/api/lookup_values/' + cid, function(data) {
        updateData(data);
      });
    }

    function updateData(data) {
      if (data.lookup_value.data_i > 0) {
        candidatePeriodNumber.val(data.lookup_value.data_i.toString());
      }else {
        candidatePeriodNumber.val('0');
      }
    }
  }
  
});