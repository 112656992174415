window.showHidePassword = function(passwordComponent) {
  var inputPasswordComponent = $(passwordComponent);
  var showHidePasswordComponent = $(passwordComponent + " ~ .js-show-hide-password");
  showHidePasswordComponent.click(function(e){
    e.preventDefault();
    if (inputPasswordComponent[0] !== undefined) {
      if (inputPasswordComponent.prop('type') === "password") {
        inputPasswordComponent.prop('type','text');
      }else {
        inputPasswordComponent.prop('type','password');
      }
    }
  });
}
