$(document).on('turbolinks:load', function (e) {
  e.preventDefault();

  var urlParams = new URLSearchParams(window.location.search);
  var tabId = urlParams.get('tab');
  var sectionId = urlParams.get('section');

  if (tabId) {
    activateTab(tabId);
  }

  if (sectionId) {
    $('html, body').animate({
      scrollTop: $('#' + sectionId).offset().top -100
    }, 200);
  }

  function activateTab(tabId) {
    var tabNames = tabId.split(',');

    tabNames.forEach(function (tabName) {
      var tabArr = tabName.split('_');
      var tabId = tabArr[0];
      var tabSection = tabArr[1] === undefined ? '' : tabArr[1];
      clearActiveTabs(tabSection);
      activateTabByName(tabId);
    });
  }

  function clearActiveTabs(tabSection) {
    document.querySelectorAll('.nav-tabs#' +tabSection+ ' .nav-item .nav-link').forEach(function (link) {
      link.classList.remove('active');
      link.setAttribute('aria-selected', 'false');
    });
    document.querySelectorAll('.tab-content#' +tabSection+ ' > .tab-pane').forEach(function (tabPane) {
      tabPane.classList.remove('show', 'active');
    });
  }

  function activateTabByName(tabId) {
    var tabLink = document.querySelector('.nav-tabs .nav-item .nav-link[href="#' + tabId + '"]');
    if (tabLink) {
      tabLink.classList.add('active');
      tabLink.setAttribute('aria-selected', 'true');
      var tabContent = document.querySelector('.tab-content #' + tabId);
      if (tabContent) {
        tabContent.classList.add('show', 'active');
      }
    }
  }
}); 