$(document).on('turbolinks:load', function () {

  // START - Monitoring reports - Employee report
  var mppSummaryOrgsSelect = $('#monitoring-reports-employee-orgs-select');
  if (mppSummaryOrgsSelect.length) {
    mppSummaryOrgsSelect.multiselect({
      includeSelectAllOption: true,
      disableIfEmpty: true,
      maxHeight: 200,
      buttonWidth: '100%'
    });
  }
  var mppDetailLocationsSelect = $('#monitoring-reports-employee-locations-select');
  if (mppDetailLocationsSelect.length) {
    mppDetailLocationsSelect.multiselect({
      includeSelectAllOption: true,
      disableIfEmpty: true,
      maxHeight: 200,
      buttonWidth: '100%'
    });
  }
  // END - Monitoring reports - Employee report

  // START - Monitoring reports - Employee status report
  var mppSummaryOrgsSelect = $('#monitoring-reports-employee-status-orgs-select');
  if (mppSummaryOrgsSelect.length) {
    mppSummaryOrgsSelect.multiselect({
      includeSelectAllOption: true,
      disableIfEmpty: true,
      maxHeight: 200,
      buttonWidth: '100%'
    });
  }
  var mppDetailLocationsSelect = $('#monitoring-reports-employee-status-locations-select');
  if (mppDetailLocationsSelect.length) {
    mppDetailLocationsSelect.multiselect({
      includeSelectAllOption: true,
      disableIfEmpty: true,
      maxHeight: 200,
      buttonWidth: '100%'
    });
  }
  // END - Monitoring reports - Employee status report

  // START - Monitoring reports - Attendance report
  var mppSummaryOrgsSelect = $('#monitoring-reports-attendance-orgs-select');
  if (mppSummaryOrgsSelect.length) {
    mppSummaryOrgsSelect.multiselect({
      includeSelectAllOption: true,
      disableIfEmpty: true,
      maxHeight: 200,
      buttonWidth: '100%'
    });
  }
  var mppDetailLocationsSelect = $('#monitoring-reports-attendance-locations-select');
  if (mppDetailLocationsSelect.length) {
    mppDetailLocationsSelect.multiselect({
      includeSelectAllOption: true,
      disableIfEmpty: true,
      maxHeight: 200,
      buttonWidth: '100%'
    });
  }
  // END - Monitoring reports - Attendance report
  
});