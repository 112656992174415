window.mutationLookupEmployee = function() {
  var url = "/api/employees/by_primary_organization_id/"
  var urlCareer = "/api/careers/latest_by_employee_id/" 
  var listenSelectComponent = "select#mutation_organization_id";
  var updateSelectComponent = "select#mutation_employee_id";
  var updateSelectCareerComponent = "select#mutation_current_career_id";
  var toOrgComponent = "select#mutation_to_organization_id";
  var jobPositionComponent = "select#mutation_job_position_id";

  var updateComponent = $(updateSelectComponent);
  var updateCareerComponent = $(updateSelectCareerComponent);
  var updateToOrgComponent = $(toOrgComponent);
  var updateJobPositionComponent = $(jobPositionComponent); 

  $(listenSelectComponent).on('change', function(event) {
    emptyExistingContent();
    fetchData();
  });

  function fetchData() {
    var orgId = $(listenSelectComponent + " option:selected").val();
    $.get(url + orgId, function(data) {
      updateSelectComponentData(data);
      if (data != null && data.employees.length > 0) {
        var employeeId = $(updateSelectComponent + " option:selected").val();
        $.get(urlCareer + employeeId, function(careerData) {
          updateSelectCareerComponentData(careerData);
        });
      }
    });
  }

  function updateSelectComponentData(data) {
    $.each(data.employees, function(key,value) {
      updateComponent.append($("<option></option>")
          .attr("value", value.id).text(value.full_name));
    });
  }

  function updateSelectCareerComponentData(data) {
    $.each(data.careers, function(key,value) {
      updateCareerComponent.append($("<option></option>")
          .attr("value", value.id).text(value.position_name));
    });
  } 

  function emptyExistingContent() {
    updateComponent.empty();
    updateCareerComponent.empty();
    updateToOrgComponent.empty();
    updateJobPositionComponent.empty();
  }
}


window.mutationLookupCareer = function() {
  var urlCareer = "/api/careers/latest_by_employee_id/" 
  var listenSelectComponent = "select#mutation_employee_id";
  var updateSelectCareerComponent = "select#mutation_current_career_id";
  var toOrgComponent = "select#mutation_to_organization_id";
  var jobPositionComponent = "select#mutation_job_position_id";

  var updateCareerComponent = $(updateSelectCareerComponent);
  var updateToOrgComponent = $(toOrgComponent);
  var updateJobPositionComponent = $(jobPositionComponent);

  $(listenSelectComponent).on('change', function(event) {
    emptyExistingContent();
    fetchData();
  });

  function fetchData() {
    var employeeId = $(listenSelectComponent + " option:selected").val();
    $.get(urlCareer + employeeId, function(careerData) {
      updateSelectCareerComponentData(careerData);
    });
  }

  function updateSelectCareerComponentData(data) {
    $.each(data.careers, function(key,value) {
      updateCareerComponent.append($("<option></option>")
          .attr("value", value.id).text(value.position_name));
    });
  } 

  function emptyExistingContent() {
    updateCareerComponent.empty();
    updateToOrgComponent.empty();
    updateJobPositionComponent.empty(); 
  }
}


window.mutationLookupOrgJobPosition = function() {

  var url = "/api/mutations/get_job_position_by_category";
  var listenSelectComponent = "#org_job_position_search";
  var currentCareerSelectComponent = "select#mutation_current_career_id";
  var categorySelectComponent = "select#mutation_category";
  var updateOrganizationComponent = "select#mutation_to_organization_id";
  var updateJobPositionComponent = "select#mutation_job_position_id";

  var currentCareer = $(currentCareerSelectComponent);
  var updateOrganization = $(updateOrganizationComponent);
  var updateJobPosition = $(updateJobPositionComponent);

  $(listenSelectComponent).on('click', function(e) {
    e.preventDefault();
    emptyExistingContent();
    fetchData();
  });

  function fetchData() {
    currentCareerValue = $(currentCareerSelectComponent + " option:selected").val();
    categoryValue = $(categorySelectComponent + " option:selected").val();
    if (currentCareerValue !== null && currentCareerValue !== undefined) {
      $.get(url + "?category=" + categoryValue + "&current_career_id=" + currentCareerValue , function(data) {
        updateSelectOrgJobPositionComponentData(data);
      });
    }
  }

  function updateSelectOrgJobPositionComponentData(data) {
    $.each(data.to_organizations, function(key,value) {
      updateOrganization.append($("<option></option>")
          .attr("value", value.id).text(value.display));
    });
    $.each(data.job_positions, function(key,value) {
      updateJobPosition.append($("<option></option>")
          .attr("value", value.id).text(value.name ));
    });
  } 

  function emptyExistingContent() {
    updateOrganization.empty();
    updateJobPosition.empty();
  }
}


window.mutationLookupJobPosition = function() {
  var url = "/lookups/update_job_positions/"
  var listenSelectComponent = "select#mutation_to_organization_id";
  var updateSelectComponent = "select#mutation_job_position_id";

  var updateComponent = $(updateSelectComponent);

  $(listenSelectComponent).on('change', function(event) {
    emptyExistingContent();
    fetchData();
  });

  function fetchData() {
    var orgId = $(listenSelectComponent + " option:selected").val();
    $.get(url + orgId, function(data) {
      updateSelectComponentData(data);
    });
  }

  function updateSelectComponentData(data) {
    $.each(data.job_positions, function(key,value) {
      updateComponent.append($("<option></option>")
          .attr("value", value.id).text(value.name));
    });
  }

  function emptyExistingContent() {
    updateComponent.empty();
  }
}


window.mutationClearOrgJobPosition = function() {
  var updateOrganizationComponent = "select#mutation_to_organization_id";
  var updateJobPositionComponent = "select#mutation_job_position_id";

  var updateOrganization = $(updateOrganizationComponent);
  var updateJobPosition = $(updateJobPositionComponent);

  $("select#mutation_current_career_id").on('change', function(event) {
    clearContent();
  });

  $("select#mutation_category").on('change', function(event) {
    clearContent();
  });

  function clearContent() {
    updateOrganization.empty();
    updateJobPosition.empty(); 
  }
}

$(document).on('turbolinks:load', function(){
  var candidateCareerStatusSelect = $('select#mutation_status_id');
  var candidatePeriodNumber = $('input#mutation_period');
  if (candidateCareerStatusSelect.length && candidatePeriodNumber.length) {
    $(candidateCareerStatusSelect).on('change', function() {
      fetchData();
    });
    function fetchData() {
      var cid = $("select#mutation_status_id option:selected").val();
      $.get('/api/lookup_values/' + cid, function(data) {
        updateData(data);
      });
    }
    function updateData(data) {
      if (data.lookup_value.data_i > 0) {
        candidatePeriodNumber.val(data.lookup_value.data_i.toString());
      }else {
        candidatePeriodNumber.val('0');
      }
    }
  }
});