$(document).on('turbolinks:load', function () {

  var dshiftPlanningUploadFile = $('#dshift-planning-upload-file');
  if (dshiftPlanningUploadFile.length) {
    dshiftPlanningUploadFile.change(function(){
      $('#overlay-on-submit-layer-js').removeClass("d-none");
      $('#dshift-planning-upload-file-form').trigger('submit');
    });
  }
});
