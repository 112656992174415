var locationLat = 0;
var locationLng = 0;
var locationRad = 0;
var locationMode = "show";


const defaultLocationLat = -2.245799634837078;
const defaultLocationLng = 121.3638578974326;
const defaultLocationZoom = 4;

window.setMapAttributes = function (lat, lng, rad, mode) {
  this.locationLat = lat;
  this.locationLng = lng;
  this.locationRad = rad;
  this.locationMode = mode;
}

window.initMap = function () {
  const map = new google.maps.Map(document.getElementById("geolocation-map"), {});
  var mapMarker;
  var mapCircle;


  var latComponent = document.querySelector("input#location_lat");
  var lngComponent = document.querySelector("input#location_lng");
  var radComponent = document.querySelector("input#location_radius");
  var refreshBtnComponent = document.querySelector("#location-btn-refresh-map");

  var position = { lat: defaultLocationLat, lng: defaultLocationLng };
  var radius = 0;
  var mapZoom = defaultLocationZoom;

  if (this.locationLat != 0 && this.locationLng != 0) {
    setAndRefreshMapAttributes(this.locationLat, this.locationLng, this.locationRad);
  } else {
    setAndRefreshMapAttributes(defaultLocationLat, defaultLocationLng, locationRad);
  }

  function setAndRefreshMapAttributes(lat, lng, rad) {
    position = {
      lat: lat,
      lng: lng
    }
    radius = rad;
    if (lat != defaultLocationLat && lat != defaultLocationLng) {
      mapZoom = 18;
    } else {
      mapZoom = defaultLocationZoom;
    }
    if (latComponent !== null) {
      latComponent.value = lat;
    }
    if (lngComponent !== null) {
      lngComponent.value = lng;
    }
    if (radComponent !== null) {
      radComponent.value = rad;
    }
    map.setZoom(mapZoom);
    map.setCenter(position);

    // create circle and pin if position value is set
    if (lat != defaultLocationLat && lat != defaultLocationLng) {
      if (mapMarker == null) {
        mapMarker = new google.maps.Marker({
          position: position,
          map
        });
      } else {
        mapMarker.setPosition(position);
      }

      if (mapCircle == null) {
        mapCircle = new google.maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.6,
          strokeWeight: 1,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          map,
          center: position,
          radius: radius,
        });
      } else {
        mapCircle.setCenter(position);
        mapCircle.setRadius(radius);
      }
    }
  }


  if (this.locationMode == "edit") {
    if (latComponent != null && lngComponent != null) {
      map.addListener("click", (mapsMouseEvent) => {
        let newPos = mapsMouseEvent.latLng.toJSON();
        setAndRefreshMapAttributes(newPos.lat, newPos.lng, radius);
      });
    }

    if (refreshBtnComponent != null) {
      refreshBtnComponent.addEventListener("click", (e) => {
        e.preventDefault();
        setAndRefreshMapAttributes(Number(latComponent.value), Number(lngComponent.value), Number(radComponent.value));
      });
    }
  }
}

