$(document).on('turbolinks:load', function(){
  var body = $('body');
  var contentWrapper = $('.content-wrapper');
  var scroller = $('.container-scroller');
  var footer = $('.footer');
  var sidebar = $('.sidebar');

  if (!body.hasClass('sidebar-icon-only') && localStorage.getItem('desktop_icon_sidebar') === "true") {
    body.addClass('sidebar-icon-only');
  }

  //Add active class to nav-link based on url dynamically
  //Active class can be hard coded directly in html file also as required
  var current = location.pathname.split("/").slice(-1)[0].replace(/^\/|\/$/g, '');
  $('.nav li a', sidebar).each(function() {
    var $this = $(this);
    if (current === "") {
      //for root url
      if ($this.attr('href').indexOf("index.html") !== -1) {
        $(this).parents('.nav-item').last().addClass('active');
        if ($(this).parents('.sub-menu').length) {
          $(this).closest('.collapse').addClass('show');
          $(this).addClass('active');
        }
      }
    } else {
      //for other url
      if ($this.attr('href').indexOf(current) !== -1) {
        $(this).parents('.nav-item').last().addClass('active');
        if ($(this).parents('.sub-menu').length) {
          $(this).closest('.collapse').addClass('show');
          $(this).addClass('active');
        }
      }
    }
  })

  //Close other submenu in sidebar on opening any

  sidebar.on('show.bs.collapse', '.collapse', function() {
    sidebar.find('.collapse.show').collapse('hide');
  });


  //Change sidebar and content-wrapper height
  applyStyles();

  function applyStyles() {
    //Applying perfect scrollbar
    if (!body.hasClass("rtl")) {
      if ($('.settings-panel .tab-content .tab-pane.scroll-wrapper').length) {
        const settingsPanelScroll = new PerfectScrollbar('.settings-panel .tab-content .tab-pane.scroll-wrapper');
      }
      if ($('.chats').length) {
        const chatsScroll = new PerfectScrollbar('.chats');
      }
      if ($('.scroll-container').length) {
        const ScrollContainer = new PerfectScrollbar('.scroll-container');
      }
      if (body.hasClass("sidebar-fixed")) {
        var fixedSidebarScroll = new PerfectScrollbar('#sidebar .nav');
      }
    }
  }

  function toggleDesktopSidebarState() {
    if (!body.hasClass('sidebar-icon-only')) {
      localStorage.setItem('desktop_icon_sidebar', true);
    }else {
      localStorage.removeItem("desktop_icon_sidebar");
    }
  }

  $('button[data-toggle="minimize"]').on("click", function(e) {
      e.preventDefault();
      if ((body.hasClass('sidebar-toggle-display')) || (body.hasClass('sidebar-absolute'))) {
        body.toggleClass('sidebar-hidden');
      } else {
        toggleDesktopSidebarState();
        body.toggleClass('sidebar-icon-only');
      }
  }); 

  //checkbox and radios
  $(".form-check .form-check-label,.form-radio .form-check-label").not(".todo-form-check .form-check-label").append('<i class="input-helper"></i>');
});
