$(document).on('turbolinks:load', function(){
  if ($('#calendar').length && !$('#calendar').children().length) {
    $('#calendar').fullCalendar({
      header: {
        left: 'prev,next today',
        center: 'title',
        right: ''
      },
      timeZone: 'local',
      eventLimit: true,
      lazyFetching: true,
      firstDay: 1,
      eventSources: [
        '/api/calendars/event'
      ]
    })
  }

  function submitChangeLoc() {
    $('#change_loc_filter_submit_button').trigger('click');
  }

  $('select#loc_filter').on('change', function() {
    submitChangeLoc();
  });
});