window.dashboardNearExpireProbationCount = function() {
  var url = "/api/dashboards/get_near_expire_probation_count"
  var updateComponent = $('#near_expire_probation_count');

  fetchData();

  function fetchData() {
    $.get(url, function(data) {
      var count = 0;
      if (data !== null) {
        count = data.count;
      }
      updateComponent.text(count);
    });
  }
}

window.dashboardAppliedCandidateCount = function() {
  var url = "/api/dashboards/get_applied_candidate_count"
  var updateComponent = $('#applied_candidate_count');

  fetchData();

  function fetchData() {
    $.get(url, function(data) {
      var count = 0;
      if (data !== null) {
        count = data.count;
      }
      updateComponent.text(count);
    });
  }
}

window.dashboardProcessingCandidateCount = function() {
  var url = "/api/dashboards/get_processing_candidate_count"
  var updateComponent = $('#processing_candidate_count');

  fetchData();

  function fetchData() {
    $.get(url, function(data) {
      var count = 0;
      if (data !== null) {
        count = data.count;
      }
      updateComponent.text(count);
    });
  }
}

window.dashboardMonthlyEmployeeBirthday = function() {
  var url = "/api/dashboards/get_monthly_employee_birthday"
  var updateComponent = $('#employee_birthday_data');

  fetchData();

  function fetchData() {
    $.get(url, function(data) {
      updateEmployeeBirthdayData(data);
    });
  }

  function updateEmployeeBirthdayData(data) {
    updateComponent.empty();
    if (data.current !== null) {
      contentTop = `
      <div class="table-responsive">
        <table class="table table-hover mt-3">
          <thead>
            <tr>
              <th style="width: 100px">Tanggal</th>
              <th>Nama karyawan</th>
            </tr>
          </thead>
          <tbody>
      `;
      contentCenter = '';
      $.each(data.employees, function(key,value) {
        contentCenter += `<tr><td><b>${value.birth_date}</b></td><td>${value.full_name}</td></tr>`;
      });
      contentBottom = `
          </tbody>
        </table>
      </div>
      `;
      updateComponent.append(contentTop + contentCenter + contentBottom);
    } else {
      updateComponent.append("<p class='text-secondary text-center h4'>No data</p>");
    }
  }
}