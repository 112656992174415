$(document).on('turbolinks:load', function(){
  let candidate_status_filter = $('#probation_status_filter');
  $('#js-probation-status-tab a').on('click', function(){
    let status = $(this).data('status');
    candidate_status_filter.val(status);
    $('#probation_filter_submit_button').trigger('click');
  });

  // Probation process status change
  var candidateCareerStatusSelect = $('select#probation_process_status_id');
  var candidatePeriodNumber = $('input#probation_process_period');
  if (candidateCareerStatusSelect.length && candidatePeriodNumber.length) {
    $(candidateCareerStatusSelect).on('change', function() {
      fetchData();
    });
    function fetchData() {
      var cid = $("select#probation_process_status_id option:selected").val();
      $.get('/api/lookup_values/' + cid, function(data) {
        updateData(data);
      });
    }
    function updateData(data) {
      if (data.lookup_value.data_i > 0) {
        candidatePeriodNumber.val(data.lookup_value.data_i.toString());
      }else {
        candidatePeriodNumber.val('0');
      }
    }
  }

});