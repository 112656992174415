$(document).on('turbolinks:load', function () {

  var geoLocation = {
    getLocation: function () {
      var deferred = $.Deferred();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(deferred.resolve, deferred.reject, { timeout: 5000 });
      } else {
        deferred.reject(new Error('Browser Anda tidak mendukung Geolocation'));
      }
      return deferred.promise();
    }
  };

  var video = document.querySelector("video#video_webcam_attendance");

  function turnOffCamera() {
    if (video != null && video.srcObject != null) {
      var mediaStream = video.srcObject;
      var tracks = mediaStream.getTracks();
      tracks[0].stop();
    }
  }

  function updateUserMedia() {
    navigator.getUserMedia = navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;
  }

  function turnOnCamera() {
    var videoComponent = $("video#video_webcam_attendance");
    if (videoComponent.html() !== undefined) {
      updateUserMedia();

      // should support for all major browsers (Chrome, Firefox, Safari OSX/iOS)
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({video: true, audio: false})
          .then(function (stream) {
            video.srcObject = stream;
            video.onloadedmetadata = function(e) {
              video.play();
            }
          })
          .catch(function (error) {
            alert("Mohon diizinkan mengakses kamera");
            history.back();
          });
      }
    }
  }

  function reTakeSnapshot() {
    $("#taken-picture").attr("src", '');
    $("#take-picture-collapse").addClass("show");
    $("#picture-collapse").removeClass("show");
    turnOnCamera();
  }

  function geolocationError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("Mohon diizinkan mengakses Geolokasi");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Informasi Geolokasi tidak tersedia");
        break;
      case error.TIMEOUT:
        alert("Timeout saat mencari informasi Geolokasi");
        break;
      case error.UNKNOWN_ERROR:
        alert("Error Geolokasi tidak diketahui");
        break;
    }
  }

  turnOffCamera();
  turnOnCamera();

  $("button#attendance_submit_button").on("click", function () {
    geoLocation.getLocation().then(function (position) {
      $("#attendance_tmp_lat").val(position.coords.latitude);
      $("#attendance_tmp_lng").val(position.coords.longitude);
    }).fail(function (err) {
      geolocationError(err);
    }).then(function () {
      $('#attendance_submit_form').trigger('submit');
    });
  });

  $("button#take_snapshot_button").on("click", function () {
    takeSnapshot();
  });

  $("button#retake_snapshot_button").on("click", function () {
    reTakeSnapshot();
  });

  $("a#app_top_backbar_button").on("click", function () {
    turnOffCamera();
  });

  function takeSnapshot() {
    var img = document.querySelector("img#taken-picture");
    var takePictureCollapse = $("#take-picture-collapse");
    var pictureCollapse = $("#picture-collapse");
    var context;
    var profile_image = document.querySelector("#attendance_tmp_image");
    var aspect = video.videoHeight / video.videoWidth;
    var wantedWidth = 300;   // or use height
    var height = Math.round(wantedWidth * aspect)

    canvas = document.createElement('canvas');
    canvas.width = wantedWidth;
    canvas.height = height;

    context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    img.src = canvas.toDataURL('image/png');
    profile_image.value = canvas.toDataURL("image/jpeg").split(';base64,')[1];

    takePictureCollapse.removeClass("show");
    pictureCollapse.addClass("show");

    // stop the camera after shoot
    turnOffCamera();
  }

});

window.AttendanceLookupEmployee = function(listenSelectComponent, updateSelectComponent) {
  var url = "/api/employees/by_primary_organization_id/"
  var updateComponent = $(updateSelectComponent);

  $(listenSelectComponent).on('change', function(event) {
    emptyExistingContent();
    fetchData();
  });

  function fetchData() {
    var orgId = $(listenSelectComponent + " option:selected").val();
    $.get(url + orgId, function(data) {
      updateSelectComponentData(data);
    });
  }

  function updateSelectComponentData(data) {
    $.each(data.employees, function(key,value) {
      updateComponent.append($("<option></option>")
          .attr("value", value.id).text(value.full_name));
    });
  }

  function emptyExistingContent() {
    updateComponent.empty();
  }
}