$(document).on('turbolinks:load', function () {

  var importDataPayrollSchemeUploadFile = $('#import-data-payroll-scheme-upload-file');
  if (importDataPayrollSchemeUploadFile.length) {
    importDataPayrollSchemeUploadFile.change(function(){
      $('#overlay-on-submit-layer-js').removeClass("d-none");
      $('#import-data-payroll-scheme-upload-file-form').trigger('submit');
    });
  }
});
