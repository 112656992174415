$(document).on('turbolinks:load', function () {
  var btnApprove = $("a#doc_approve_btn_approve");
  var btnReject = $("a#doc_approve_btn_reject");
  var approvalStatus = $("#doc_approve_approval_status");
  var submitForm = $("form#doc_approve_form");

  function submitDocumentApproveForm() {
    if (submitForm.length) {
      submitForm.trigger('submit');
    }
  }

  if (approvalStatus.length) {
    if (btnApprove.length) {
      btnApprove.on("click", function (e) {
        e.preventDefault();
        approvalStatus.val("approved");
        if (confirm("Konfirmasi DISETUJUI ?")) {
          submitDocumentApproveForm();
        }
      });
    }

    if (btnReject.length) {
      btnReject.on("click", function (e) {
        e.preventDefault();
        approvalStatus.val("rejected");
        if (confirm("Konfirmasi DITOLAK ?")) {
          submitDocumentApproveForm();
        }
      });
    }
  }
});
