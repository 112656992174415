// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require('jquery-ui');
require('clipboard');
require('moment');


// Radiant admin bootstrap4 theme custom js
import './radiant/misc';
import './radiant/off-canvas';
import './radiant/hoverable-collapse';
import './radiant/settings';
import './radiant/vendor.bundle.base';
import './radiant/clipboard';
import './radiant/fullcalendar.min';
import './radiant/bootstrap-multiselect';

// ganesa custom js
import './ganesa/dashboard';
import './ganesa/print_this';
import './ganesa/lookups';
import './ganesa/warning_letter';
import './ganesa/resignation';
import './ganesa/mutation';
import './ganesa/gototop';
import './ganesa/alert';
import './ganesa/file_upload';
import './ganesa/candidate_status_selection';
import './ganesa/probation_status_selection';
import './ganesa/show_hide_password';
import './ganesa/calendar_view';
import './ganesa/competency_level';
import './ganesa/shift';
import './ganesa/attendance';
import './ganesa/app';
import './ganesa/document_approve';
import './ganesa/google_map';
import './ganesa/monitoring_dashboard';
import './ganesa/monitoring_report';
import './ganesa/candidate';
import './ganesa/public_career';
import './ganesa/user';
import './ganesa/general_announcement';
import './ganesa/holiday';
import './ganesa/work_schedule';
import './ganesa/dshift_planning';
import './ganesa/overlay_on_submit';
import './ganesa/payroll_transaction';
import './ganesa/consecutive_absent';
import './ganesa/employee_update';
import './ganesa/attendance_correction';
import './ganesa/shift_change';
import './ganesa/import_data';
import './ganesa/anchor';