$(document).on('turbolinks:load', function () {

  var dshiftPlanningUploadFile = $('#payroll-transaction-detail-upload-file');
  if (dshiftPlanningUploadFile.length) {
    dshiftPlanningUploadFile.change(function(){
      $('#overlay-on-submit-layer-js').removeClass("d-none");
      $('#payroll-transaction-detail-upload-file-form').trigger('submit');
    });
  }

  var payrollTransactionShowLocationsSelect = $('#payroll-transaction-show-locations-select');
  if (payrollTransactionShowLocationsSelect.length) {
    payrollTransactionShowLocationsSelect.multiselect({
      includeSelectAllOption: true,
      disableIfEmpty: true,
      maxHeight: 200,
      buttonWidth: '300px'
    });
  }

});
