window.warningLetterLookupEmployee = function(listenSelectComponent, updateSelectComponent, currentDataComponent, typeSelectComponent ) {
  var url = "/api/employees/by_primary_organization_id/"
  var updateComponent = $(updateSelectComponent);
  var currentDataInfoComponent = $(currentDataComponent);
  var typeComponent = $(typeSelectComponent);

  $(listenSelectComponent).on('change', function(event) {
    emptyExistingContent();
    fetchData();
  });

  function fetchData() {
    var orgId = $(listenSelectComponent + " option:selected").val();
    $.get(url + orgId, function(data) {
      updateSelectComponentData(data);
    });
  }

  function updateSelectComponentData(data) {
    $.each(data.employees, function(key,value) {
      updateComponent.append($("<option></option>")
          .attr("value", value.id).text(value.full_name));
    });
  }

  function emptyExistingContent() {
    updateComponent.empty();
    currentDataInfoComponent.empty();
    typeComponent.empty();
  }
}

window.warningLetterLookupType = function(listenComponent, valueSelectComponent, updateSelectComponent, currentDataComponent) {

  var url = "/api/warning_letters/by_employee_id/"
  var updateComponent = $(updateSelectComponent);
  var currentDataInfoComponent = $(currentDataComponent);

  $(listenComponent).on('click', function(e) {
    e.preventDefault();
    fetchData();
  });

  function fetchData() {
    var empId = $(valueSelectComponent + " option:selected").val();
    $.get(url + empId, function(data) {
      updateSelectComponentData(data);
      updateCurrentWarningLetter(data);
    });
  }

  function updateSelectComponentData(data) {
    updateComponent.empty();
    if (data.warning_letters !== null && data.warning_letters.length > 0) {
      $.each(data.warning_letters, function(key,value) {
        updateComponent.append($("<option></option>")
            .attr("value", value.id).text(value.label));
      });
    }
  }

  function updateCurrentWarningLetter(data) {
    currentDataInfoComponent.empty();
    if (data.current !== null) {
      content = `
      <div class="table-responsive">
        <table class="table">
          <tbody><tr>
            <th style="width: 150px">Jenis surat peringatan</th>
            <td style="width: 10px">:</td>
            <td>${data.current.label}</td>
          </tr>
          <tr>
            <th>Tanggal efektif</th>
            <td>:</td>
            <td>${data.current.effective_date}</td>
          </tr>
          <tr>
            <th>Sisa hari kadaluarsa</th>
            <td>:</td>
            <td>${data.current.days_remain_expired}</td>
          </tr>
        </table>
      </div>
      `;
      currentDataInfoComponent.append(content);
    } else {
      currentDataInfoComponent.append("<p class='text-secondary text-center h4'>Tidak ada data</p>");
    }
  }
}
