$(document).on('turbolinks:load', function () {

  var userSelectallOrgs = $('#user-selectall-orgs');
  if (userSelectallOrgs.length) {
    userSelectallOrgs.click(function() {
      if(this.checked) {
        $('input[id^="user_organization_ids_"]').each(function(){
          this.checked = true;
        });
      } else {
        $('input[id^="user_organization_ids_"]').each(function(){
          this.checked = false;
        });
      } 
    });
  }

  var userSelectallOrgs = $('#user-selectall-locs');
  if (userSelectallOrgs.length) {
    userSelectallOrgs.click(function() {
      if(this.checked) {
        $('input[id^="user_location_ids_"]').each(function(){
          this.checked = true;
        });
      } else {
        $('input[id^="user_location_ids_"]').each(function(){
          this.checked = false;
        });
      } 
    });
  }

});