window.shiftPlanningLookupShift = function(listenSelectComponent, updateSelectComponent) {
  var url = "/api/shifts/by_organization_id/"
  var updateComponent = $(updateSelectComponent);

  $(listenSelectComponent).on('change', function(event) {
    emptyExistingContent();
    fetchData();
  });

  function fetchData() {
    var orgId = $(listenSelectComponent + " option:selected").val();
    $.get(url + orgId, function(data) {
      updateSelectComponentData(data);
    });
  }

  function updateSelectComponentData(data) {
    $.each(data.shifts, function(key,value) {
      updateComponent.append($("<option></option>")
          .attr("value", value.id).text(value.name));
    });
  }

  function emptyExistingContent() {
    updateComponent.empty();
  }
}