$(document).on('turbolinks:load', function () {

  var userSelectallOrgs = $('#geneal-announcement-selectall-orgs');
  if (userSelectallOrgs.length) {
    userSelectallOrgs.click(function() {
      if(this.checked) {
        $('input[id^="general_announcement_organization_ids_"]').each(function(){
          this.checked = true;
        });
      } else {
        $('input[id^="general_announcement_organization_ids_"]').each(function(){
          this.checked = false;
        });
      } 
    });
  }

});