$(document).on('turbolinks:load', function () {

  var shiftChangeFromSchedule = $('#shift_change_from_schedule');
  var shiftChangeFromScheduleButton = $('#shift_change_from_schedule_button');
  var shiftChangeFromShift = $('#shift_change_from_dshift_planning_id');

  var shiftChangeToSchedule = $('#shift_change_to_schedule');
  var shiftChangeToScheduleButton = $('#shift_change_to_schedule_button');
  var shiftChangeToShift = $('#shift_change_to_dshift_planning_id');

  var shiftDataPath = '/api/shift_changes/get_shift_data';
  if (shiftChangeFromSchedule.length && shiftChangeFromScheduleButton.length) {

    $(shiftChangeFromScheduleButton).on('click', function(e) {
      e.preventDefault();
      if (shiftChangeFromSchedule.val() !== '') {
        shiftChangeFromShift.empty(); 
        $.get(shiftDataPath + '?schedule=' +  shiftChangeFromSchedule.val()+'&mode=self', function(data) {
          if (data != null && data.shifts.length > 0) {
            $.each(data.shifts, function(key,value) {
              shiftChangeFromShift.append($("<option></option>")
                  .attr("value", value.id).text(value.shift_name + ' ['+value.shift_code+']'));
            });
          }
        });
      }
    });
  }

  if (shiftChangeToSchedule.length && shiftChangeToScheduleButton.length) {
    $(shiftChangeToScheduleButton).on('click', function(e) {
      e.preventDefault();
      if (shiftChangeToSchedule.val() !== '') {
        shiftChangeToShift.empty(); 
        $.get(shiftDataPath + '?schedule=' +  shiftChangeToSchedule.val(), function(data) {
          if (data != null && data.shifts.length > 0) {
            $.each(data.shifts, function(key,value) {
              shiftChangeToShift.append($("<option></option>")
                  .attr("value", 
                    value.id).text(value.shift_name + ' ['+value.shift_code+'] - '+value.emp_name));
            });
          }
        });
      }
    });
  }
});