window.lookupAppGetLeaveCategory = function(leaveCategory, maxAllowed, annualLeaveBalance) {
  var url = "/api/apps/get_leave_category/"
  var maxAllowedComponent = $(maxAllowed);
  var annualLeaveBalanceGroup = $("#"+annualLeaveBalance+"_group");
  var annualleaveBalanceTextBox = $("input#"+annualLeaveBalance);

  $(leaveCategory).on('change', function(event) {
    fetchData();
  });

  function fetchData() {
    var selectedId = $(leaveCategory + " option:selected").val();
    $.get(url + selectedId, function(data) {
      var days = "";
      var annualLeaveBalanceValue = "";
      if (data !== null) {
        days = data.leave_category.max_allowed_per_request;
        annualLeaveBalanceValue = data.leave_category.annual_leave_balance;
        if (data.leave_category.annual_leave == true) {
          annualLeaveBalanceGroup.show();
        }else{
          annualLeaveBalanceGroup.hide();
        }
      }
      maxAllowedComponent.val(days);
      annualleaveBalanceTextBox.val(annualLeaveBalanceValue);
    });
  }
}

$(document).on('turbolinks:load', function(){
  $('[data-toggle="tooltip"]').tooltip();
}); 

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
})